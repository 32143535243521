@use "../../../styles/typography";
@use "../../../styles/colors";

.container {
  min-width: 800px;
  max-width: 1600px;
  padding: 0.5rem;
  margin: auto;
  margin-bottom: 2rem;
  z-index: 1;
  position: relative;
}

.usage {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tabs {
  padding-left: 24px;
}

.header {
  font-size: 18px;
  font-weight: typography.$font-weight-semi-bold;
}

.avatar {
  width: 80px;
  height: 80px;
}

.key {
  color: colors.$middle-dark-gray;
  min-width: 7rem;
}

.nameField {
  div {
    height: 24px;
    padding: 0px;
  }

  textarea {
    line-height: 1.5;
  }
}

.deleteButton {
  color: colors.$mui-error-dark;
}

.card {
  width: 120px;
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: colors.$lightest-gray;
  }
}

.warning-dialog-accounts {
  margin-top: 14px;
  margin-bottom: 14px;
}
