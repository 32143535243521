@use "../../../styles/colors";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 1px;
}

.highlighted {
  border-radius: 2px;
  padding: 3px;
  margin: -2px;
  box-shadow: 0 0 0 2px colors.$pixel-point-blue;

  &:hover {
    background-color: colors.$pixel-point-blue;
    opacity: 0.5;
  }
}
