@use "../../styles/colors";

.ui-control-card {
  cursor: pointer;
  width: 150px;
  height: 120px;
  cursor: pointer;

  border-radius: 4px;

  background-color: colors.$pixel-point-blue;
  display: inline-flex;

  transition: 0.2s ease-in-out;
}

.ui-control-card:hover {
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: colors.$pixel-point-blue-hover;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55px; // size to scale icon
  width: 70px; //viewable area
  height: 70px;
}

.destination-icon {
  height: 55px;
  width: 55px;
}

.resource-card {
  width: 120px;
  height: 120px;

  border-radius: 8px;
  background: linear-gradient(
    315deg,
    colors.$white 21.02%,
    colors.$background-white 86.36%
  );

  border: 1px colors.$light-gray solid;

  box-shadow: 0px 2px 8px 0px colors.$black-shadow;

  transition: background-color 0.2s ease-in-out;
}

$connector-card-width: 200px;

.router-connector-card {
  @extend .resource-card;
  width: $connector-card-width;
  height: auto;
}

.router-connector-card:hover,
.resource-card:hover,
.processor-card-v2:hover,
.processor-card:hover {
  background-color: colors.$light-gray;
  transition: background-color 0.2s ease-in-out;
  background: linear-gradient(
    315deg,
    colors.$light-gray 21.02%,
    colors.$light-gray 86.36%
  );
}

.router-connector-title {
  width: $connector-card-width;
  height: 40px;
  background-color: colors.$very-light-gray;
  padding-right: 80px;
}

.router-connector-route {
  position: relative;
  width: $connector-card-width;
  height: 40px;
  border-top: 1px solid colors.$light-gray;
  padding-right: 80px;
}

.bar-graph-background {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 80px;
  height: 100%;
  border-left: 1px solid colors.$light-gray;
  background: linear-gradient(
    to right,
    rgba(0, 115, 229, 0.08) var(--percentage),
    rgba(0, 0, 0, 0) var(--percentage)
  );
}

.router-connector-throughput {
  font-size: 9pt;
  text-align: center;
}

.resource-card-no-hover {
  width: 120px;
  height: 120px;

  border-radius: 8px;
  background: linear-gradient(
    315deg,
    colors.$white 21.02%,
    colors.$background-white 86.36%
  );

  border: 1px colors.$light-gray solid;

  box-shadow: 0px 2px 8px 0px colors.$black-shadow;
}

.action {
  height: 120px;
}

.processorAction {
  height: 60px;
}

.processorActionV2 {
  height: 70px;
}

.cursorDefault {
  cursor: default;
}

.title,
.description {
  font-weight: 600;
  white-space: nowrap;

  position: absolute;
  top: 150px;
  width: 200px;
  left: -40px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 16px;
  top: 120px;
}

.add-title {
  font-size: 16px;
}

.description {
  font-size: 12px;
  top: 150px;
}

.processor-card,
.dummy-processor-card {
  height: 60px;
  width: 60px;
  margin: -1px;

  border-radius: 8px;
  background: linear-gradient(
    315deg,
    colors.$white 21.02%,
    colors.$background-white 86.36%
  );

  border: 1px colors.$light-gray solid;
  box-shadow: 0px 2px 8px 0px colors.$black-shadow;

  transition: background-color 0.2s ease-in-out;
}

.processor-card-v2 {
  @extend .processor-card;
  height: 70px;
  width: 150px;
  .bar-graph-background {
    height: 70px;
  }
}

.dummy-processor-card-v2 {
  @extend .processor-card;
  height: 70px;
  width: 70px;
}

.processor-percentage {
  font-size: 9pt;
  margin-top: 2px;
  svg {
    text-align: baseline;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}

.processor-recommendation {
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  border-radius: 8px;
  padding: 2px;
  margin: -2px;
  background: linear-gradient(
    45deg,
    colors.$pixel-point-blue,
    transparent,
    colors.$pixel-point-blue,
    transparent,
    colors.$pixel-point-blue
  );
  animation: shimmer 50s linear infinite;

  // special case for v2
  .v2-graph & {
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: linear-gradient(
      25deg,
      colors.$pixel-point-blue,
      transparent,
      colors.$pixel-point-blue,
      transparent,
      colors.$pixel-point-blue
    );
    animation: shimmer 25s linear infinite;
  }
}

@keyframes shimmer {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: 2000px;
  }
}

.button {
  width: 150px;
  height: 120px;
  cursor: pointer;

  background-color: colors.$white;

  transition: background-color 0.2s ease-in-out;
}

.count-chip {
  position: absolute;
  bottom: 45px;
  left: 45px;
}

.count-chip-v2 {
  position: absolute;
  bottom: 55px;
  left: 55px;
}

.overview-count-chip {
  position: absolute;
  top: -12px;
  left: -8px;
  background-color: colors.$white;
  border: 1px solid colors.$pixel-point-blue;

  height: 26px;
}

.overview-count-chip-label {
  font-size: 16px;
  color: colors.$pixel-point-blue;
}

.disabled {
  opacity: 0.4;
}

.paused {
  background-color: colors.$light-gray;
  opacity: 1;
}

.pause-icon {
  position: absolute;
  top: 8px;
  left: 8px;
}

.bookmark-icon {
  position: absolute;
  top: -6px;
  right: 8px;
}
