@use "../../styles/colors";

.banner {
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  font-size: 16px;
}
