@use "../../styles/colors";

.content {
  padding: 0 16px 0 16px;
}

.divider {
  border-color: colors.$very-light-gray;
}

.typography {
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
