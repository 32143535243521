@use "../../styles/colors";

.card {
  border-top: none;
  border-left: 1px solid colors.$middle-light-gray;
  border-bottom: 1px solid colors.$middle-light-gray;
  border-right: 1px solid colors.$middle-light-gray;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    radial-gradient(rgb(235, 235, 235) 2px, transparent 0);
  background-size: 20px 20px;
}

.container {
  border: none;
  flex-grow: 1;
}

.accordionSummary {
  background-color: colors.$very-light-gray;
  border: 1px solid colors.$middle-light-gray;
  border-radius: 4px;

  min-height: 32px !important;
  max-height: 32px !important;

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
