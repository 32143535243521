@use "../../styles/colors";

.current {
  opacity: 1 !important;
  background-color: none !important;

  display: flex;
  justify-content: space-between;
}

.check {
  color: colors.$pixel-point-blue;
}

.current-typo {
  color: colors.$pixel-point-blue;
  font-weight: 600;
}

.info-stack {
  padding: 0 10px;
}
