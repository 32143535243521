@use "../../styles/colors";

.metric {
  font-size: 12px;
}

.overview-metric {
  font-size: 12px;
}

.inactive {
  stroke-width: 1px;
}

.edge {
  stroke: colors.$middle-light-gray;

  fill: none;
}

.dashes {
  stroke: colors.$semitone-blue;
  stroke-dasharray: 10 3;
  &.condition {
    stroke: colors.$neon-carrot;
    stroke-dasharray: 6 7;
  }
  &.default {
    stroke: colors.$middle-gray;
    stroke-dasharray: 6 7;
  }
}

.w1 {
  stroke-width: 2px;
}

.w2 {
  stroke-width: 5px;
}

.w3 {
  stroke-width: 8px;
}

.w4 {
  stroke-width: 11px;
}

.w5 {
  stroke-width: 14px;
}

.dimmed {
  opacity: 0.3;
}
