.help-menu-icon {
    margin-right: 8px;
}

.help-menu-button {
    width: 100%;
}

.help-menu-fab {
    position: fixed;
    bottom: 26px;
    right: 26px;
}

.help-menu {
    margin-top: -4px;
}
