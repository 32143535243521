@use "../../styles/colors";

.form-container {
  border: 1px solid colors.$light-gray;
  border-radius: 4px;

  height: 100%;

  height: 0;
  flex-grow: 1;
}

.editor-container {
  height: 100%;
  padding: 8px 0px 8px 16px;
  overflow: auto;
}

.snapshot-container-left {
  flex-grow: 1;
  // Unclear why, but width needs specified for the flex-grow to behave
  width: 0;
}

.snapshot-container-right {
  flex-grow: 1;
  // Same as above
  width: 0;
}

.snapshot-menu ul {
  padding-top: 0;
  padding-bottom: 0;
}

.snapshot-menu li {
  padding-right: 30px;
}

.snapshot-menu li.snapshot-menu-heading {
  margin: auto 0;
  border-radius: 0;
  padding: 5px 10px;
  color: colors.$black;
  opacity: 1 !important;
  font-size: 0.8rem;
  background-color: colors.$light-gray;
}
.breadcrumb-container {
  background: colors.$background-white;
  padding: 4px 16px 4px 16px;
  border-radius: 4px 4px 0 0;
}

.breadcrumb-text {
  font-size: 14px;
}
