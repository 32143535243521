@use "../../styles/colors";

.pointer {
  cursor: pointer;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;

  width: 40px;
  height: 40px;

  transition: background-color 0.2s ease;

  border-radius: 15px;
  background-color: colors.$white;
}

.overrideIcon {
  width: 40px;
  height: 40px;
  
  transition: background-color 0.2s ease;
  
  border-radius: 15px;  
  background-color: colors.$white;
}

.hovered {
  background-color: colors.$light-gray;
  border: none;
}

.card {
  border: none;
}

.box {
  height: 100%;
  min-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 5px;

  border: 1px solid colors.$light-gray;
  border-radius: 4px;

  width: 100%;
}
