@use "../../styles/typography";
@use "../../styles/colors";

.editor {
  max-height: 500px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  border: 0.5px solid colors.$light-gray;
  font-size: 12px;
  font-family: typography.$font-family-monospace;
  background-color: colors.$very-light-gray;
  border-radius: 4px;
}

.expanded {
  max-height: 10000px;
  transition: max-height 0.5s ease-in-out;
}

.container {
  overflow: auto;
}

.editable {
  background-color: colors.$white;
  overflow: auto;
}

.bg-white {
  background-color: colors.$white;
}

.actions {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  padding-top: 8px;
  display: flex;
  gap: 8px;
  overflow: auto;
}