@use "../../styles/colors";

.dialog { 
  overflow-y: auto;
}

.close {
  float: right;
}

.info {
  margin-top: 10px;
  display: inline-flex;
}
