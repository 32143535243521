@use "../../styles/colors";

.card {
  width: 116px;
  height: 65px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: colors.$lightest-gray;
    cursor: pointer;
  }
}

.cardContent {
  width: 100%;
}

.cardText {
  padding-left: 8px;
}
