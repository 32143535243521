@use "../../../styles/colors";

.noFill {
  fill: none;
  stroke-width: 3px;
}

.inactiveRoute {
  stroke: colors.$middle-dark-gray;
}

.activeRoute {
  stroke: colors.$semitone-blue;
  stroke-dasharray: 10 3;
}

.dimmed {
  opacity: 0.3;
}

.hovered {
  stroke-dasharray: unset;
  stroke-width: 3 !important;
}

.metric {
  font-size: 12px;
}

.w1 {
  stroke-width: 4px;
}

.w2 {
  stroke-width: 7px;
}

.w3 {
  stroke-width: 10px;
}

.w4 {
  stroke-width: 13px;
}

.w5 {
  stroke-width: 16px;
}
