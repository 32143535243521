@use "../../styles/colors";
@use "../../styles/typography";

.app-bar-root {
  background: colors.$white;
  box-shadow: 0px 4px 4px 0px colors.$black-shadow;
  border: none;
}

.toolbar {
  min-height: unset !important;
  height: 50px;
}

.nav-link {
  color: colors.$very-dark-gray;
  text-decoration: none;

  display: flex;
  align-items: center;
  padding: 16px 16px;
  line-height: 18px;

  font-weight: typography.$font-weight-medium;
  font-size: 16px;

  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;

  .icon {
    margin-right: 6px;
    box-sizing: content-box;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    stroke: colors.$middle-light-gray;
    &.active {
      stroke: colors.$pixel-point-blue;
    }
  }

  &:hover {
    color: colors.$black;
  }

  &.active,
  &.active:hover {
    color: colors.$pixel-point-blue;
  }
}

.main-nav {
  margin: 0 24px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.sub-nav {
  margin: 0 0 0 auto;
  display: inline-flex;
  flex-wrap: nowrap;

  .button {
    margin: auto 5px;
  }

  .icon {
    box-sizing: content-box;
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    stroke: colors.$very-dark-gray;
  }
}

.logout-modal-paper {
  padding: 2rem;
}

.logo {
  width: 120px;
  height: 35px;
  margin-bottom: -8px;
}
