@use "../../../styles/typography";
@use "../../../styles/colors";

.container {
  min-width: 800px;
  max-width: 1600px;
  padding: 0.5rem;
  margin: auto;
  margin-bottom: 2rem;
  z-index: 1;
  position: relative;
}

.header {
  font-size: 18px;
  font-weight: typography.$font-weight-semi-bold;
}

.tab-header {
  font-size: 24px;
  font-weight: typography.$font-weight-semi-bold;
}

.avatar {
  width: 80px;
  height: 80px;
}

.box {
  border: 1px solid colors.$middle-light-gray;
  border-radius: 10px;
  background-color: colors.$white;

  tr:last-child td {
    border-bottom: none;
  }
}

.lowercase {
  text-transform: none !important;
}

.tabs {
  padding-left: 24px;
}

.menu {
  min-width: 150px;
}

.paper {
  height: 450px;
}

.tooltip {
  background-color: colors.$white;
  border: 1px solid colors.$middle-dark-gray;
}

.code {
  width: 100%;
  max-width: none;
  margin-top: 16px;
}

.key {
  color: colors.$middle-dark-gray;
  min-width: 7rem;
}
