@use "../../styles/colors.scss" as colors;

.bg {
  background-color: #fafafa;
  width: 100vw;
  height: 100vh;
}

.auth0Card {
  width: 400px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
}

.auth0Heading {
  padding: 40px 40px 24px;
  line-height: 1.5;
  text-align: center;
  flex-shrink: 0;
}

.auth0Body {
  padding: 0 40px 40px 40px;
  text-align: center;

  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;
  align-items: center;
}
