@use "../../styles/colors";

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.header-right {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  align-items: center;
  padding-right: 16px;
}

.upper-form-control {
  background-color: white;
  border-radius: 4px;
  width: 140px;
  height: 40px;
  fieldset {
    border: 1px solid colors.$light-gray;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  }
}

.form-control {
  background-color: white;
  border-radius: 4px;
  width: 240px;
  height: 40px;
  fieldset {
    border: 1px solid colors.$light-gray;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  }
}
.content {
  padding: 0 16px 0 16px;
}

.select-component {
  height: 40px;
}

.menu-item {
  .menu-item-content {
    display: flex;
    align-items: center;
    height: 40px;

    .icon {
      width: 40px;
      height: 20px;
      stroke: "blue";
    }

    .text {
      margin-left: 8px;
    }
  }

  .selected-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
.destination-icon {
  width: 40px;
  height: 20px;
  stroke: colors.$darkest-gray;
}

.divider {
  border-color: colors.$very-light-gray;
}

.vertical-divider {
  border-color: colors.$very-light-gray;
  height: 55px;
}
