.dialog {
    overflow-y: auto;
    height: fit-content;
    padding-right: 0px;
}

.form {
    height: 100%;
    overflow-y: scroll;
    padding-right: 16px;
    padding-top: 8px;
}