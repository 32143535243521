@use "../../styles/colors.scss";

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.button {
  width: 34px;
  height: 34px;
}

.error {
  color: colors.$mui-error-dark;
}
