@use "../../styles/colors";

.hover-icon {
  color: colors.$middle-light-gray;
  cursor: grab;
  flex-shrink: 0;
}
.hover {
  cursor: pointer;

  &:hover {
    background-color: colors.$background-white;
  }
}

.button {
  font-weight: 300;

  min-width: unset;
  padding: 4px 2px;
}

.card {
  padding: 5px;
  margin: 5px 0;
  min-height: 40px;
}

.recommendationCard {
  margin: 5px 0;

  position: relative;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    colors.$pixel-point-blue,
    transparent,
    colors.$pixel-point-blue,
    transparent,
    colors.$pixel-point-blue
  );
  animation: shimmer 5s linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: 2000px;
  }
}

.shortDescription {
  font-weight: normal;
}

.transparentTooltip {
  background-color: transparent;
  padding: 0;
  max-width: 400px;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;

  width: 25px;
  height: 25px;
  flex-shrink: 0;
  transition: background-color 0.2s ease;
}

.card-button {
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
}

.card-button-icon {
  width: 16px;
  height: 16px;
}

.chevron {
  width: 20px;
  transition: transform 0.2s;
}

.sub {
  background-repeat: no-repeat;
  background-position: center;

  width: 25px;
  height: 25px;
  flex-shrink: 0;
  transition: background-color 0.2s ease;
}
