@use "../../styles/colors";

.category {
  background-color: colors.$very-light-gray;
  padding: 8px 16px;
}

.bundleCategory {
  background-color: #ebf4fd;
  padding: 8px 16px;
}
.overrideIcon {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
}
