@use "../../../styles/typography";
@use "../../../styles/colors";

.chip {
  height: 22px;
}

.chip-label {
  font-family: typography.$font-family-monospace;
  max-width: 400px;
}

// in wide 12 column boxes, allow larger chips
:global .MuiGrid-grid-xs-12 {
  :local .chip-label {
    max-width: 600px;
  }
}

.telemetry-header-root {
  width: 100%;
  display: flex;
  justify-content: space-between;

  background: colors.$light-gray;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 0;
}

.telemetry-header-switch {
  float: right;
}

.metric-category-label {
  background: colors.$light-gray;
  border-radius: 4px;

  padding: 4px 8px;
}

.metric-category-button {
  border: none;
  cursor: pointer;
  color: colors.$pixel-point-blue;
  background-color: colors.$light-gray;

  font-size: 12px;
}

.metric-category-button:hover {
  background: none;
  text-decoration: underline;
}

.metric-label {
  margin: -6px 16px -6px -11px;
}

.listbox {
  max-height: 200px;
  overflow: auto;
}

// Custom styling for the ToggleButton to look like pills
.chip-button {
  border-radius: 40px !important;
  border: 1px colors.$pixel-point-blue solid;
  border-left: 1px colors.$pixel-point-blue solid !important;
  border-right: 1px colors.$pixel-point-blue solid !important;
  margin: 0px 5px !important;
  padding: 2px 10px;

  &:global(.Mui-selected) {
    background-color: colors.$pixel-point-blue !important;
    color: colors.$white;
  }

  &:global(.Mui-selected:hover) {
    background-color: colors.$pixel-point-blue-hover !important;
    border: 1px colors.$pixel-point-blue-hover solid;
    border-left: 1px colors.$pixel-point-blue-hover solid !important;
    border-right: 1px colors.$pixel-point-blue-hover solid !important;
  }
  &:global(.Mui-disabled) {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}

.span {
  font-size: 0;
  line-height: 0;
}

// ----------- for ConditionInput ------------

$condition-bracket-color: colors.$middle-light-gray;
$condition-bracket-radius: 10px;
$condition-bracket-width: 8px;

.condition-input {
  z-index: 1000;
}

.condition-group {
  margin-left: 6px;
  border-width: 0;
  position: relative;
  border-style: solid;
  border-color: $condition-bracket-color;
  border-width: 0 0 0 1px;
  border-radius: $condition-bracket-radius 0 0 $condition-bracket-radius;

  // Leaving this here commented out for now in case we decide we want to use this
  // approach for a more rigid bracket design

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   bottom: 4px;
  //   left: 0;
  //   width: $condition-bracket-width;
  //   border-style: solid;
  //   border-color: $condition-bracket-color;
  //   border-width: 1px 0 1px 1px;
  //   border-radius: $condition-bracket-radius 0 0 $condition-bracket-radius;
  // }

  .condition-statement {
    position: relative;
    padding: 10px 0 12px 14px;
  }

  .condition-group-operator-container {
    position: relative;
    margin-top: 0px;
  }

  .condition-group-operator {
    left: -6px;
    z-index: 1000;
    background-color: colors.$white;
    min-width: 70px;

    & > div {
      font-size: 0.8rem;
    }
  }

  .condition-buttons {
    position: absolute;
    bottom: -16px;
    right: 0;
    width: 50%;
  }
}

.routes-input {
  .condition-input {
    margin-top: -12px;
  }
}
