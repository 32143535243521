@use "../../styles/colors";

.card {
  z-index: 1;
  background: colors.$background-white;
  border-radius: 12px;
  border-color: colors.$very-light-gray;
}
.divider {
  border-color: colors.$very-light-gray;
}

.card-content {
  padding: 0;
}
