@use "../../../styles/colors";

.key-column {
  font-weight: 900;
  min-width: 11rem;
  margin-bottom: 0.5rem;
  color: colors.$middle-dark-gray;
}

.upgrade-button {
  margin-left: 0.75rem;
  top: -0.25rem;
}

.platform-icon {
  margin-right: 7px;
  position: relative;
}

.config-link {
  font-size: 1rem;
  top: -2px;
}

.encryption-icon {
  max-width: 1rem;
}
