@use "../../styles/colors";

.handle:hover {
  background-color: colors.$pixel-point-blue;
  cursor: col-resize;
}

.handle-horizontal:hover {
  background-color: colors.$pixel-point-blue;
  cursor: row-resize;
}
