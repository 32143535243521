@use "../../../styles/colors";

// TODO: This is identical to SourceTable/cells.module.scss. Refactor unless necessary

.icon {
  width: 30px;
  height: 30px;
  position: relative;
  background-repeat: no-repeat;  
  filter: invert(.2);
}

.cell {
  display: flex;
  align-items: center;
}

.link {
  // Unset all native button styles
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  color: colors.$semitone-blue;
  text-decoration: underline;
}
