@use "../../styles/colors";

.divider {
  border-color: colors.$very-light-gray;
}

.content {
  padding: 0 16px 0 16px;
}

.tableContainer {
  background: colors.$background-white;
  overflow-y: auto;
}

.cell {
  border-bottom: 1px solid colors.$very-light-gray;
  padding-left: 0px;
  width: 150px;
}

.greyText {
  color: colors.$dark-gray;
}

.lineCell {
  border-bottom: 1px solid colors.$very-light-gray;
  padding-left: 0px;
  width: 400px;
  border-left: none;
}

.link {
  text-decoration: none;
}

.tableRow {
  &:last-child {
    td {
      border-bottom: none;
    }
  }
}
