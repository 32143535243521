@use "../../styles/colors";

.box {
  height: 510px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 5px;

  border: 1px solid colors.$light-gray;
  border-radius: 4px;
}

.overrideIcon {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
}

.category {
  background-color: colors.$very-light-gray;
  padding: 8px 16px;
}